import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/header"
import Container from "../../components/container"
import AnimationWrapper from "../../components/animationWrapper"
import TextSection from "../../components/textSection"
import ContactSection from "../../components/contactSection"
import ChooseTilesSection from "../../components/chooseTilesSection"
import TestimonialsSection from "../../components/testimonialsSection"

import "./dom.css"
import "../../components/infobox.css"

const DomPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Dom" data={data.header} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section} />
          </AnimationWrapper>
        </Container>
      }

      {data.page.frontmatter.choose_tiles_section &&
        <section id="choose-house-section">
          <ChooseTilesSection data={data.page.frontmatter.choose_tiles_section} />
        </section>
      }

      {data.page.frontmatter.testimonials_section &&
        <section id="testimonials" class="page-section">
          <AnimationWrapper>
            <TestimonialsSection textSectionData={data.page.frontmatter.testimonials_section} testimonials={data.testimonials.nodes}  />
          </AnimationWrapper>
        </section>
      }

      {data.page.frontmatter.contact_section &&
        <section id="contact-data" className="page-section">
          <ContactSection data={data.page.frontmatter.contact_section} />
        </section>
      }

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "dom" }, type: { eq: "page" } }) {
      frontmatter{
        title
        text_section{
          title
          title_visible
          align
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          button_text
          button_url
          button_style
          text
          label_icon
          label_text
        }
        choose_tiles_section{
          title
          title_visible
          subtitle
          button_text
          button_url
          button_style
          text
          type
          choose_tiles{
            name
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
            link
            type
            status
            description
            button_text
            button_style
            file
          }
        }
        testimonials_section{
          title
          title_visible
          subtitle
          type
          image
          text
        }
        contact_section{
          title
          description
          type
          submit_button_style
          fields
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/dom-header.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    testimonials: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {internal: {contentFilePath: {regex: "/testimonials/"}}}
    ){
      nodes {
        frontmatter {
          title
          slug
          order
          active
          text
        }
      }
    }
  }
`

export default DomPage
